<div *transloco="let t; prefix: 'login'" class="min-h-screen flex justify-center items-center">
  <div class="w-full max-w-md rounded-lg border-1 border-black/30 py-6 px-1 sm:px-6 m-3">
    <div class="text-center">
      <h1 class="text-6xl font-semibold text-primary mb-2">{{t('welcome')}}</h1>
      <p class="text-md text-gray-600">{{t('enterCredentials')}}</p>
    </div>
    <div class="grid gap-6 my-8 px-6">
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="flex flex-col gap-1 mb-3">
          <input type="text" pInputText formControlName="user" [placeholder]="t('user.placeholder')"  fluid="true" />
          <p-message *ngIf="hasError('user')" severity="error" variant="simple" size="small">{{t('user.required')}}</p-message>
        </div>
        <div class="flex flex-col gap-1 mb-3">
          <p-password formControlName="password" [feedback]="false" [toggleMask]="true" [placeholder]="t('password.placeholder')" fluid="true" />
          <p-message *ngIf="hasError('password')" severity="error" variant="simple" size="small">{{t('password.required')}}</p-message>
        </div>
        <div class="mt-10">
          <p-button [label]="t('button')" type="submit" fluid="true" />
        </div>
      </form>
      <div class="flex justify-center mt-4">
        <p-button [label]="t('forgot')" [link]="true" styleClass="font-light" (onClick)="forgotPasswordClick()"/>
      </div>
    </div>
  </div>
</div>

