import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent, TableLabels, ToolbarConfig } from '@components/table';
import { mockData, mockColumns } from '../../mock/data';
import { I18nService } from '@core/i18n';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arquea-partners-list',
  imports: [TableComponent],
  templateUrl: './partners-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersListComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private i18nService = inject(I18nService);

  tableLabels = signal<TableLabels>({});
  toolbarConfig: ToolbarConfig = {
    hasAddBtn: true
  };

  cols = mockColumns;
  data = mockData;

  private subscription = new Subscription();

  ngOnInit(): void {
    this.initLabels();
  }

  private initLabels(): void {
    this.subscription.add(
      this.i18nService.selectTranslateObject('list', {}, 'partners').subscribe((res) => {
        this.tableLabels.set(res);
      })
    );
  }

  goToAddPartner(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  goToPartner(item: any): void {
    this.router.navigate([item.id], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
