import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableComponent, TableLabels, ToolbarConfig } from '@components/table';
import { I18nService } from '@core/i18n';
import { mockColumns, mockCustomersData } from '@views/customers-view/mock/data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arquea-customers-list',
  standalone: true,
  imports: [TableComponent],
  templateUrl: './customers-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersListComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private i18nService = inject(I18nService);

  tableLabels = signal<TableLabels>({});
  toolbarConfig: ToolbarConfig = {
    hasAddBtn: true
  };

  cols = mockColumns;
  data = mockCustomersData;

  private subscription = new Subscription();

  ngOnInit(): void {
    this.initLabels();
  }

  private initLabels(): void {
    this.subscription.add(
      this.i18nService.selectTranslateObject('list', {}, 'customers').subscribe((res) => {
        this.tableLabels.set(res);
      })
    );
  }

  goToAddCustomer(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  goToCustomer(item: any): void {
    this.router.navigate([item.id], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
