import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';

const tnpPreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: '#f2f7fa',
      100: '#c2d8e7',
      200: '#98bfd7',
      300: '#689fc5',
      400: '#2b7fb3',
      500: '#005b9b',
      600: '#004d84',
      700: '#00406d',
      800: '#003255',
      900: '#00243e',
      950: '#001727'
    }
  }

});

export default tnpPreset;
