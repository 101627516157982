import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /* ================================================
    Methods for sessionStorage
  ================================================ */

  /**
   * Stores a value in sessionStorage.
   * @param key The storage key.
   * @param value The value to store.
   */
  setSessionStorage(key: string, value: string): void {
    sessionStorage.setItem(this.prepareKey(key), value);
  }

  /**
   * Retrieves a value from sessionStorage.
   * @param key The storage key.
   * @returns The stored value or null if not found.
   */
  getSessionStorage(key: string): string | null {
    return sessionStorage.getItem(this.prepareKey(key));
  }

  /**
   * Removes a value from sessionStorage.
   * @param key The storage key.
   */
  removeSessionStorage(key: string): void {
    sessionStorage.removeItem(this.prepareKey(key));
  }

  /* ================================================
    Methods for localStorage
  ================================================ */

  /**
   * Stores a value in localStorage.
   * @param key The storage key.
   * @param value The value to store.
   */
  setLocalStorage(key: string, value: string): void {
    localStorage.setItem(this.prepareKey(key), value);
  }

  /**
   * Retrieves a value from localStorage.
   * @param key The storage key.
   * @returns The stored value or null if not found.
   */
  getLocalStorage(key: string): string | null {
    return localStorage.getItem(this.prepareKey(key));
  }

  /**
   * Removes a value from localStorage.
   * @param key The storage key.
   */
  removeLocalStorage(key: string): void {
    localStorage.removeItem(this.prepareKey(key));
  }

  /* ================================================
    Auxiliary Methods (AUX)
  ================================================ */

  /**
   * Prepares keys to be consistent, adding a 'arquea-' prefix if it doesn't exist.
   * @param rawKey The original key provided.
   * @returns The modified key with the 'arquea-' prefix if necessary.
   */
  private prepareKey(rawKey: string): string {
    const prefix = 'arquea-';
    return rawKey.startsWith(prefix) ? rawKey : `${prefix}${rawKey}`;
  }
}
