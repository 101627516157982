<div class="card">
  @if (hasToolbar()) {
    <p-toolbar styleClass="mb-4 !p-2">
      <ng-template #start>
        @if (toolbarConfig().hasAddBtn) {
          <p-button [label]="labels().add" icon="pi pi-plus" class="mr-2" (onClick)="clickNew()" [size]="size()" />
        }
      </ng-template>
      <ng-template #center>
        @if (toolbarConfig().hasSearchFilter) {
          <p-iconfield iconPosition="left">
            <p-inputicon styleClass="pi pi-search" />
            <input type="text" pInputText [pSize]="size()" placeholder="Search" />
          </p-iconfield>
        }
      </ng-template>

      <ng-template #end>
        @if (toolbarConfig().hasOptions) {
          <p-menu #menu [model]="items" [popup]="true" />
          <p-button (click)="menu.toggle($event)" icon="pi pi-ellipsis-v" [size]="size()" severity="secondary" />
        }
      </ng-template>
    </p-toolbar>
  }

  <p-table
    [columns]="columns()"
    [value]="data()"
    [size]="size()"
    [showGridlines]="showGridLines()"
    [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template #header let-columns>
      <tr>
        @for (col of columns; track $index) {
        <th>
          {{ col.header }}
        </th>
        }
        <th></th>
      </tr>
    </ng-template>
    <ng-template #body let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track $index) {
          <td>
            {{ rowData[col.field] }}
          </td>
        }
        <td class="flex justify-end">
          <p-button icon="pi pi-search" severity="secondary" variant="text" styleClass="text-sm" (onClick)="handleRowClick(rowData)"/>
        </td>
      </tr>
    </ng-template>

    <ng-template #footer> <ng-content select="[footerTable]"/> </ng-template>
  </p-table>
</div>
