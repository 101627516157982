export const mockColumns = [
  { field: 'code', header: 'Code' },
  { field: 'name', header: 'Name' }
];
export const mockCustomer = {
  name: 'Customer one',
  active: true,
  email: 'cus@customer.com',
  phone: '60493',
  mobile: '23993',
  cif: '92929',
  shippingAddress: [
    {
      name: 'Address one',
      address: 'Address 1, 5A',
      postalCode: '2929',
      city: 'City one',
      province: 'State one',
      country: 'Country one'
    },
    {
      name: 'Address tow',
      address: 'Address 2, 9B',
      postalCode: '134',
      city: 'City two',
      province: 'State tow',
      country: 'Country two'
    }
  ],
  billAddress: null,
  contact: {
    name: 'Admin',
    email: 'admin@customer.com',
    phone: '12345'
  }
};

export const mockCustomersData = [
  {
    name: 'Customer one',
    active: true,
    email: 'cus@customer.com',
    phone: '60493',
    mobile: '23993',
    cif: '92929',
    shippingAddress: [
      {
        name: 'Address one',
        address: 'Address 1, 5A',
        postalCode: '2929',
        city: 'City one',
        province: 'State one',
        country: 'Country one'
      },
      {
        name: 'Address tow',
        address: 'Address 2, 9B',
        postalCode: '134',
        city: 'City two',
        province: 'State tow',
        country: 'Country two'
      }
    ],
    billAddress: null,
    contact: {
      name: 'Admin',
      email: 'admin@customer.com',
      phone: '12345'
    }
  }
];
