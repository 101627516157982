import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomersListComponent } from './components';

@Component({
  selector: 'app-customers-view',
  standalone: true,
  imports: [CustomersListComponent],
  templateUrl: './customers-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersViewComponent { }
