import { inject, Injectable } from '@angular/core';
import { ApiRestService } from './api-rest.service';
import { endpoints } from '@core/config';
import { first } from 'rxjs';
import { UserState, UserStore } from '@core/store';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private router = inject(Router);
  private apiRestService = inject(ApiRestService);
  private userStore = inject(UserStore);
  private tokenService = inject(TokenService);

  getUserData(): void {
    if (this.tokenService.isTokenValid()) {
      this.apiRestService.get(endpoints.roleManager.getUser).pipe(
        first()
      ).subscribe(
        {
          next: (user) => {
            const data = user as UserState;
            this.userStore.setUser(data);
          },
          error: () => {
            this.tokenService.removeSession();
            this.router.navigate(['/login']);
          }
        });
    }
  }
}
