import { Component, inject, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@components/footer';
import { HeaderComponent } from '@components/header';
import { SidebarComponent } from '@components/sidebar';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, HeaderComponent, FooterComponent, SidebarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'arquea';
  isLoginView = signal<boolean>(false);
  private router: Router = inject(Router);

  ngOnInit(): void {
    this.checkRoutes();
  }

  private checkRoutes(): void {
    this.router.events.pipe(
      filter((event) => {
        return event instanceof NavigationEnd;
      })
    ).subscribe((navigationEvent: NavigationEnd) => {
      this.isLoginView.set(navigationEvent.url.split('/').includes('login'));
    });
  }
}
