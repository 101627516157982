import { inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private storage = inject(StorageService);
  getAccessToken(): string | null {
    return this.storage.getLocalStorage('accessToken');
  }

  getRefreshToken(): string | null {
    return this.storage.getLocalStorage('refreshToken');
  }

  setSession(accessToken: string, refreshToken: string): void {
    this.storage.setLocalStorage('accessToken', accessToken);
    this.storage.setLocalStorage('refreshToken', refreshToken);
  }

  removeSession(): void {
    this.storage.removeLocalStorage('accessToken');
    this.storage.removeLocalStorage('refreshToken');
  }

  getDecodeToken(token: string): any {
    return jwtDecode(token);
  }

  isTokenValid(expireType = false): boolean {
    const token = expireType ? this.getRefreshToken() : this.getAccessToken();
    if (!token) {
      return false;
    }

    const decodedToken: any = this.getDecodeToken(token);

    const expirationDate = decodedToken.exp * 1000;
    return Date.now() < expirationDate;
  }

  isTokenExpired(token: string): boolean {
    const expiryTime: number = this.getExpiryTime(token);
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    }
    else {
      return false;
    }
  }

  private getExpiryTime(token: string) {
    const decodedToken: any = this.getDecodeToken(token);
    return decodedToken ? decodedToken.exp : null;
  }
}
