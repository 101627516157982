import type { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService, TokenService } from '@core/services';
import { mergeMap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const tokenService = inject(TokenService);

  if (req.url.endsWith('/token') || req.url.endsWith('/refresh')) {
    return next(req);
  }

  const token = tokenService.getAccessToken();
  const refreshToken = tokenService.getRefreshToken();
  if (token && tokenService.isTokenValid()) {
    const authReq = req.clone(setAuth(token));
    return next(authReq);
  }
  else {
    if (refreshToken) {
      return authService.doRefresh(refreshToken).pipe(
        mergeMap((response: any) => {
          tokenService.setSession(response.access_token, response.refresh_token);
          const authReq = req.clone(setAuth(response.access_token));
          return next(authReq);
        })
      );
    }
  }

  return next(req);
};

const setAuth = (token: string) => {
  return {
    setHeaders: {
      Authorization: `Bearer ${token}`
    }
  };
};
