import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, inject, provideAppInitializer, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { providePrimeNG } from 'primeng/config';

import { routes } from './app.routes';
import { translocoConfig, l10nConfig, TranslocoHttpLoader } from '@core/i18n';
import tnpPreset from '@core/themes/tnp.theme';
import { authInterceptor } from '@core/interceptors';
import { UserService } from '@core/services';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([
        authInterceptor
      ])
    ),
    provideAppInitializer(() => {
      console.log('INIT APP');
      const userService = inject(UserService);
      userService.getUserData();
    }),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: tnpPreset,
        options: {
          darkModeSelector: 'system',
          cssLayer: {
            name: 'primeng',
            order: 'tailwind, theme, primeng'
          }
        }
      }
    }),
    provideTransloco({
      config: translocoConfig,
      loader: TranslocoHttpLoader
    }),
    provideTranslocoLocale(l10nConfig)
  ]
};
