import { environment } from '../../../environments/environment';

const apiVersion = environment.apiVersion;
const baseApi = `/api/logic-core/api/${apiVersion}`;
const baseCustomerManagerApi = `/api/role-manager/api/v1`;

export const endpoints = {
  roleManager: {
    getUser: `${baseCustomerManagerApi}/user`,
    getToken: `${baseCustomerManagerApi}/user/token`
  },
  partners: {
    add: `${baseApi}/partners`,
    getAll: `${baseApi}/partners`,
    getById: `${baseApi}/partners`
  },
  customers: {
    add: `${baseApi}/customers`,
    getAll: `${baseApi}/customers`,
    getById: `${baseApi}/customers`
  }
};
