import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { endpoints } from '@core/config';
import { TokenService, ApiRestService, UserService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiRestService = inject(ApiRestService);
  private tokenService = inject(TokenService);
  private userService = inject(UserService);
  private apiUrl = '/api/role-manager';

  isLogged(): boolean {
    return !!this.tokenService.getAccessToken();
  }

  login(username: string, password: string) {
    const accessToken = this.tokenService.getAccessToken();
    const refreshToken = this.tokenService.getRefreshToken();

    if (accessToken && !this.tokenService.isTokenExpired(accessToken)) {
      return of({ access_token: accessToken, refresh_token: refreshToken });
    }

    if (refreshToken && !this.tokenService.isTokenExpired(refreshToken)) {
      return this.doRefresh(refreshToken);
    }
    else {
      return this.doLogin(username, password);
    }
  }

  doLogin(username: string, password: string): Observable<any> {
    const body = {
      username: username,
      password: password,
      clientId: environment.clientId,
      clientSecret: environment.clientSecret,
      grantType: 'password',
      scope: environment.scope
    };
    return this.apiRestService.post(endpoints.roleManager.getToken, body).pipe(
      tap((res: any) => {
        this.tokenService.setSession(res.access_token, res.refresh_token);
      }),
      map((data) => {
        this.initUser();
        return data;
      }),
      catchError((err) => { return err; })
    );
  }

  doRefresh(refreshToken: string): Observable<any> {
    const body = {
      refresh_token: refreshToken,
      clientId: environment.clientId,
      clientSecret: environment.clientSecret,
      grantType: 'refresh_token'
    };
    return this.apiRestService
      .post(this.apiUrl, body)
      .pipe(
        tap((res: any) => {
          this.tokenService.setSession(res.access_token, res.refresh_token);
        }),
        catchError((error) => {
          this.tokenService.removeSession();
          throw error;
        })
      );
  }

  logout() {
    this.tokenService.removeSession();
  }

  private initUser(): void {
    this.userService.getUserData();
  }
}
