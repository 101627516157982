import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Card } from 'primeng/card';

@Component({
  standalone: true,
  imports: [Card],
  templateUrl: './partner-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerDetailsComponent { }
