import { ChangeDetectionStrategy, Component, input, output, ViewChild } from '@angular/core';
import { Table, TableModule } from 'primeng/table';
import { Column, TableLabels, ToolbarConfig } from './schemas';
import { Button } from 'primeng/button';
import { Toolbar } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { IconField } from 'primeng/iconfield';
import { InputIcon } from 'primeng/inputicon';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'arquea-table',
  imports: [TableModule, Toolbar, Button, IconField, InputTextModule, InputIcon, Menu],
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class TableComponent {
  columns = input<Column[]>([]);
  data = input<any[]>([]);
  labels = input<TableLabels>({});
  size = input<'small' | 'large'>('small');
  showGridLines = input<boolean>(false);
  hasRowDetails = input<boolean>(false);
  hasToolbar = input<boolean>(false);
  toolbarConfig = input<ToolbarConfig>({});

  onNewClick = output<void>();
  onRowClick = output<any>();

  @ViewChild('dt') dt!: Table;

  items: MenuItem[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'Export',
          icon: 'pi pi-upload'
        }
      ]
    }
  ];

  clickNew(): void {
    this.onNewClick.emit();
  }

  handleRowClick(data: any): void {
    this.onRowClick.emit(data);
  }

  exportCSV() {
    this.dt.exportCSV();
  }
}
