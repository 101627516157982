<!-- FooterComponent.html -->
<footer *transloco="let t;" class="bg-gray-800 p-4 sticky bottom-0">
  <div class="container mx-auto flex justify-between items-center">
    <div class="text-white">
      {{t('footer.copy')}}
    </div>
    <div class="flex items-center">
      <span class="text-white mr-2">{{t('footer.powered')}}</span>
      <img src="tnp_white.webp" alt="Logo" class="w-40" />
    </div>
  </div>
</footer>
