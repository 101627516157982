import { inject, Injectable } from '@angular/core';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';

@Injectable({
  providedIn: 'root'
})
export class L10nService {
  #localeService = inject(TranslocoLocaleService);

  setLocale(locale: string): void {
    this.#localeService.setLocale(locale);
  }
}
