import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PartnersListComponent } from './components';
import { OrganizationService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  imports: [PartnersListComponent],
  templateUrl: './partners-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class PartnersViewComponent implements OnInit, OnDestroy {
  private organizationService = inject(OrganizationService);
  subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(this.getPartners());
  }

  private getPartners(): void {
    this.organizationService.getPartners().subscribe((res) => {
      console.log(res);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
