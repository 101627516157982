// IMPORTANT: id property is used to identify the translation key in the i18n service.
export const sidebarItems = [
  {
    id: 'home',
    label: 'Home',
    icon: 'pi pi-home',
    routerLink: '/',
    routerLinkActiveOptions: {
      exact: true
    }
  },
  {
    id: 'partners',
    label: 'Partners',
    icon: 'pi pi-building-columns',
    routerLink: '/admin/partners',
    routerLinkActiveOptions: {
      exact: false
    }
  },
  {
    id: 'customers',
    label: 'Customers',
    icon: 'pi pi-shop',
    routerLink: '/admin/customers',
    routerLinkActiveOptions: {
      exact: false
    }
  }
];
