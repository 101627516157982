import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Button } from 'primeng/button';
import { FloatLabel } from 'primeng/floatlabel';
import { InputText } from 'primeng/inputtext';
import { Checkbox } from 'primeng/checkbox';
import { Organization } from '@core/enums';

@Component({
  selector: 'address-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputText,
    FloatLabel,
    Checkbox,
    Button
  ],
  templateUrl: './address-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDialogComponent {
  labels = input<any>({});
  type = input<Organization>(Organization.partner);

  closeClick = output<void>();
  addClick = output<any>();

  addressForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    isBillAddress: new FormControl(true)
  });

  partner = Organization.partner;

  add(): void {
    if (this.addressForm.valid) {
      this.addClick.emit(this.addressForm.value);
      this.addressForm.reset();
    }
  }

  cancel(): void {
    this.addressForm.reset();
    this.closeClick.emit();
  }
}
