import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { Button } from 'primeng/button';
import { Menu } from 'primeng/menu';
import { Menubar } from 'primeng/menubar';

import { TranslocoModule } from '@jsverse/transloco';
import { I18nService } from 'app/core/i18n';
import { Subscription } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@core/services';

@Component({
  selector: 'tnp-header',
  standalone: true,
  imports: [TranslocoModule, RouterLink, Menubar, Button, Menu],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  private i18nService = inject(I18nService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private subscription = new Subscription();

  langs = signal<MenuItem[]>([]);

  ngOnInit(): void {
    this.buildLangItems();
  }

  changeLang(lang: string): void {
    this.subscription?.unsubscribe();
    this.subscription = this.i18nService
      .load(lang)
      .subscribe(() => {
        this.i18nService.setActiveLang(lang);
      });
  }

  getCurrentLang(): string {
    return this.i18nService.getActiveLang().toUpperCase();
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  isLogged(): boolean {
    return this.authService.isLogged();
  }

  private buildLangItems(): void {
    const langs: MenuItem[] = this.i18nService.buildLangSelector();
    this.langs.set(langs);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
