import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { UserStore } from '@core/store';
import { MenuItem } from 'primeng/api';
import { Button } from 'primeng/button';
import { Menu } from 'primeng/menu';
import { Divider } from 'primeng/divider';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { sidebarItems } from '@core/config';
import { Subscription } from 'rxjs';
import { I18nService } from '@core/i18n';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'arquea-sidebar',
  imports: [CommonModule, RouterLink, RouterLinkActive, Menu, Button, Divider, Tooltip],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SidebarComponent implements OnInit, OnDestroy {
  readonly userStore = inject(UserStore);
  items = signal<MenuItem[]>([]);
  isClosed = signal<boolean>(false);

  private i18nService = inject(I18nService);
  private subscription = new Subscription();

  ngOnInit(): void {
    this.initSidebarItems();
  }

  initSidebarItems(): void {
    this.subscription.add(
      this.i18nService.selectTranslateObject('sidebar').subscribe((res) => {
        this.items.set(sidebarItems.map((item) => {
          return {
            ...item,
            label: res[item.id],
            tooltip: res[item.id]
          };
        }));
      }));
  }

  toggleSidebar() {
    this.isClosed.set(!this.isClosed());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
