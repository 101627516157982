import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UserStore } from '@core/store';
import { TranslocoModule } from '@jsverse/transloco';

import { ButtonModule } from 'primeng/button';

@Component({
  standalone: true,
  imports: [ButtonModule, TranslocoModule],
  templateUrl: './home-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeViewComponent {
  readonly store = inject(UserStore);
}
