import { inject, Injectable } from '@angular/core';
import { AvailableLangs, Translation, TranslocoService } from '@jsverse/transloco';
import { Observable, take } from 'rxjs';

/**
 * I18nService provides methods to manage internationalization (i18n) using TranslocoService.
 */
@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private translocoService = inject(TranslocoService);

  /**
   * Loads the specified language.
   * @param lang - The language to load.
   * @returns An Observable of the loaded translation.
   */
  load(lang: string): Observable<Translation> {
    return this.translocoService
      .load(lang)
      .pipe(take(1));
  }

  /**
   * Sets the active language.
   * @param lang - The language to set as active.
   */
  setActiveLang(lang: string): void {
    this.translocoService.setActiveLang(lang);
  }

  /**
   * Gets the currently active language.
   * @returns The active language as a string.
   */
  getActiveLang(): string {
    return this.translocoService.getActiveLang();
  }

  /**
   * Gets the available languages.
   * @returns An array of available languages.
   */
  getLangs(): AvailableLangs {
    return this.translocoService.getAvailableLangs();
  }

  /**
   * Builds a language selector array.
   * @returns An array of objects representing the available languages.
   */
  buildLangSelector() {
    const langs: any[] = [];
    const availableLangs: AvailableLangs = this.getLangs();
    availableLangs.map((lang) => {
      const key = lang.toString();
      langs.push({
        id: key
      });
    });
    return langs;
  }

  /**
   * Selects a translation object based on the provided key, parameters, and scope.
   * @param key - The key for the translation.
   * @param params - Optional parameters for the translation.
   * @param scope - Optional scope for the translation.
   * @returns An Observable of the translation object.
   */
  selectTranslateObject(key: string, params?: any, scope?: string): Observable<any> {
    return this.translocoService.selectTranslateObject(key, params, scope);
  }
}
