export const mockColumns = [
  { field: 'code', header: 'Code' },
  { field: 'name', header: 'Name' }
];
export const mockPartner = {
  name: 'Partner One',
  email: 'partnerone@partner.com',
  phone: '12423',
  mobile: '1234532',
  cif: '123451234',
  shippingAddress: [
    {
      name: 'Address one',
      address: 'Fake address 1. 16',
      city: 'New city',
      postalCode: '12345',
      province: 'Fake state',
      country: 'Fakeland'
    }
  ],
  billAddress: {
    name: 'Address one',
    address: 'Fake address 1. 16',
    city: 'New city',
    postalCode: '12345',
    province: 'Fake state',
    country: 'Fakeland'
  },
  contact: {
    name: 'Admin',
    email: 'admin@partner.com',
    phone: '123445'
  }
};

export const mockData = [
  {
    name: 'Partner One',
    email: 'partnerone@partner.com',
    phone: '12423',
    mobile: '1234532',
    cif: '123451234',
    shippingAddress: [
      {
        name: 'Address one',
        address: 'Fake address 1. 16',
        city: 'New city',
        postalCode: '12345',
        province: 'Fake state',
        country: 'Fakeland'
      }
    ],
    billAddress: {
      name: 'Address one',
      address: 'Fake address 1. 16',
      city: 'New city',
      postalCode: '12345',
      province: 'Fake state',
      country: 'Fakeland'
    },
    contact: {
      name: 'Admin',
      email: 'admin@partner.com',
      phone: '123445'
    }
  }
];
