import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Dialog } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { Fieldset } from 'primeng/fieldset';
import { FloatLabel } from 'primeng/floatlabel';
import { InputText } from 'primeng/inputtext';
import { Divider } from 'primeng/divider';
import { Tooltip } from 'primeng/tooltip';
import { AddressDialogComponent } from './address-dialog/address-dialog.component';
import { Address, Customer, Partner } from '@core/schemas';
import { Organization } from '@core/enums';
import { MessageService, ToastMessageOptions } from 'primeng/api';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'arquea-organization-form',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputText,
    FloatLabel,
    Button,
    Fieldset,
    Dialog,
    Divider,
    Tooltip,
    Toast,
    AddressDialogComponent
  ],
  templateUrl: './organization-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService]
})
export class OrganizationFormComponent implements OnInit {
  private messageService = inject(MessageService);
  type = input<Organization>(Organization.partner);
  labels = input<any>({});
  loading = input<boolean>(false);

  onCancel = output<void>();
  onSubmit = output<Partner | Customer>();

  organizationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    mobile: new FormControl('', []),
    cif: new FormControl('', [Validators.required]),
    shippingAddress: new FormArray<any>([]),
    billAddress: new FormGroup({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      province: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    }),
    contact: new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [])
    })
  });

  showDialog = signal<boolean>(false);
  disabledAddBtn = signal<boolean>(false);
  submitted = signal<boolean>(false);
  partner = Organization.partner;

  ngOnInit(): void {
    this.updateOrganizationForm();
  }

  updateOrganizationForm(): void {
    if (this.type() === Organization.customer) {
      this.organizationForm.controls.billAddress.disable();
    }
  }

  get shippingAddress() {
    return this.organizationForm.controls['shippingAddress'] as FormArray;
  }

  handleSubmit(): void {
    this.submitted.set(true);
    this.disabledAddBtn.set(true);
    if (this.organizationForm.valid) {
      console.log(this.organizationForm.value);
      this.onSubmit.emit(this.organizationForm.value as any);
    }
    else {
      this.organizationForm.markAllAsTouched();
      this.disabledAddBtn.set(false);
    }
  }

  handleCancel(): void {
    this.onCancel.emit();
  }

  addToast(severity: string, summary: string, detail: string): void {
    const toast: ToastMessageOptions = {
      severity,
      summary,
      detail,
      closable: true,
      key: 'bc'
    };
    severity === 'error' ? toast.life = 5000 : toast.sticky = true;
    this.messageService.add(toast);
  }

  handleToastClose(): void {
    this.disabledAddBtn.set(false);
  }

  // Address methods

  createAddress(address: any): void {
    if (address) {
      const { isBillAddress, ...addressItem } = address;
      this.shippingAddress.push(new FormControl(addressItem));

      if (isBillAddress && this.type() === Organization.partner) {
        this.organizationForm.controls.billAddress.setValue(addressItem);
      }
    }

    this.closeDialog();
  }

  removeBillAddress(): void {
    this.organizationForm.controls.billAddress.reset();
  }

  removeAddress(index: number): void {
    this.shippingAddress.removeAt(index);
  }

  isBillAddress(address: Address): boolean {
    return JSON.stringify(this.organizationForm.controls.billAddress.value) === JSON.stringify(address);
  }

  setAsBillAddress(index: number): void {
    const address = this.shippingAddress.at(index);
    this.organizationForm.controls.billAddress.setValue(address.value);
  }

  openDialog(): void {
    this.showDialog.set(true);
  }

  closeDialog(): void {
    this.showDialog.set(false);
  }
}
