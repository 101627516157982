<p-menubar *transloco="let t">
  <ng-template #start>
  <img src="Logo_ARQ_horizontal_4T.svg" alt="arquea" class="h-10"/>
  </ng-template>
  <ng-template #end>
    <div class="flex items-center gap-2">
      <p-button data-cy="i18n-button" [label]="getCurrentLang()" icon="pi pi-language" size="small" variant="text" severity="secondary" (click)="menu.toggle($event)" />
      <p-menu data-cy="i18n-menu" #menu [model]="langs()" [popup]="true">
        <ng-template #item let-item>
          <a pRipple class="flex items-center p-menu-item-link" (click)="changeLang(item.id)">
            <span [class]="item.icon"></span>
            <span class="ml-2">{{ t('langs.' + item.id) }}</span>
          </a>
        </ng-template>
      </p-menu>
      @if (isLogged()) {
        <p-button data-cy="btn-logout" (onClick)="logout()" [label]="t('logout')" size="small" />
      } @else {
        <p-button [routerLink]="['/login']" routerLinkActive="router-link-active"  [label]="t('login')"  size="small"/>
      }

    </div>
  </ng-template>
</p-menubar>
