import { User } from '@core/schemas';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {}
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((userStore: any) => ({
    setUser(user: Partial<UserState>): void {
      patchState(userStore, (state: UserState) => {
        return {
          ...state,
          ...user
        };
      });
    }
  }))
);
