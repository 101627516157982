import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Password } from 'primeng/password';
import { InputText } from 'primeng/inputtext';
import { Button } from 'primeng/button';
import { Message } from 'primeng/message';
import { CommonModule } from '@angular/common';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { AuthService } from '@core/services';
import { Router } from '@angular/router';

@Component({
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, InputText, Password, Button, Message],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'login' }],
  templateUrl: './login-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginViewComponent implements OnInit {
  private authService = inject(AuthService);
  private router = inject(Router);
  loginForm!: FormGroup;
  submitted = signal<boolean>(false);

  ngOnInit() {
    this.loginForm = new FormGroup({
      user: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  get user() {
    return this.loginForm.get('user');
  }

  get password() {
    return this.loginForm.get('password');
  }

  login(): void {
    this.submitted.set(true);
    const val = this.loginForm.value;

    if (val.user && val.password) {
      this.authService.login(val.user, val.password)
        .subscribe(
          (res) => {
            console.log('User is logged in');
            console.log('res :>> ', res);
            this.router.navigateByUrl('/');
          }
        );
    }
  }

  forgotPasswordClick(): void {
    console.log('Forgot password');
  }

  hasError(controlName: string): boolean {
    const control = this.loginForm.get(controlName);
    if (!control) {
      return false;
    }

    return this.submitted() && control.invalid && (control.dirty || control.touched);
  }
}
