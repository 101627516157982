import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IntRestParams } from '@core/schemas';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  private http = inject(HttpClient);

  /* =======================================================================
    CRUD METHODS
    ======================================================================= */

  /**
   * Executes a GET request.
   * @param url - The endpoint URL.
   * @param optionParams - Optional query parameters.
   * @returns Observable with the expected response type.
   */
  public get<T>(url: string, optionParams?: IntRestParams): Observable<T> {
    const params = optionParams
      ? new HttpParams({ fromObject: optionParams })
      : undefined;
    const headers = this.getHeaders();
    return this.http.get<T>(url, { headers, params }).pipe(
      catchError(this.handleError) // Handles potential errors in the request.
    );
  }

  /**
   * Executes a POST request with a JSON body.
   * @param url - The endpoint URL.
   * @param body - The body to be sent in the request.
   * @param reqHeaders - Optional headers.
   * @param optionParams - Optional query parameters.
   * @returns Observable with the expected response type.
   */
  public post<T>(
    url: string,
    body: any,
    reqHeaders?: HttpHeaders,
    optionParams?: IntRestParams
  ): Observable<T> {
    const params = optionParams
      ? new HttpParams({ fromObject: optionParams })
      : undefined;
    const headers: HttpHeaders = reqHeaders || this.getHeaders();
    return this.http
      .post<T>(url, body, { headers, params })
      .pipe(catchError(this.handleError));
  }

  // Other methods like get, put, delete, etc.

  /* =======================================================================
    HELPER METHODS
    ======================================================================= */

  /**
   * Generates default HTTP headers.
   * @param contentType - Content type of the request (default is JSON).
   * @returns HttpHeaders object.
   */
  private getHeaders(
    contentType: string = 'application/json;charset=utf-8'
  ): HttpHeaders {
    return new HttpHeaders().set('Content-Type', contentType);
  }

  /**
   * Handles errors in HTTP requests.
   * Logs the error and returns an Observable error.
   * @param error - The error thrown by the request.
   * @returns Observable that throws an error.
   */
  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(
      () => new Error('An error occurred while processing the request.')
    );
  }
}
