import { isDevMode } from '@angular/core';

export const translocoConfig = {
  availableLangs: ['en', 'fr'],
  fallbackLang: 'en',
  defaultLang: 'en',
  reRenderOnLangChange: true,
  prodMode: !isDevMode(),
  missingHandler: {
    useFallbackTranslation: true
  }
};
