<div class="space-y-4">
  <h1 class="text-4xl">{{labels().title}}</h1>
  <p class="text-gray-500">{{labels().description}}</p>
  <form [formGroup]="organizationForm" (ngSubmit)="handleSubmit()" class="flex flex-col gap-2">
    <p-fieldset [legend]="labels().basicSection" [toggleable]="true">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="col-span-1 md:col-span-2">
          <p-floatlabel variant="on">
            <input pInputText id="name" formControlName="name" autocomplete="off" fluid="true" pSize="small" />
            <label for="name">{{labels().name}}</label>

          </p-floatlabel>
        </div>

        <div class="col-span-1">
          <p-floatlabel variant="on">
            <input pInputText id="vat" formControlName="email" autocomplete="off" fluid="true" pSize="small" />
            <label for="email">{{labels().email}}</label>
          </p-floatlabel>
        </div>
        <div class="col-span-1">
          <p-floatlabel variant="on">
            <input pInputText id="cif" formControlName="cif" autocomplete="off" fluid="true" pSize="small" />
            <label for="cif">{{labels().cif}}</label>
          </p-floatlabel>
        </div>
        <div class="col-span-1">
          <p-floatlabel variant="on">
            <input pInputText id="phone" formControlName="phone" autocomplete="off" fluid="true" pSize="small" />
            <label for="phone">{{labels().phone}}</label>
          </p-floatlabel>
        </div>
        <div class="col-span-1">
          <p-floatlabel variant="on">
            <input pInputText id="mobile" formControlName="mobile" autocomplete="off" fluid="true" pSize="small" />
            <label for="mobile">{{labels().mobile}}</label>
          </p-floatlabel>
        </div>
      </div>
    </p-fieldset>
    <p-fieldset [legend]="labels().addressSection" [toggleable]="true" [collapsed]="true">
      @if (type() === partner) {
        <span class="text-md font-bold mb-2 ">{{labels().billingAddress}}</span>
        @if (organizationForm.controls.billAddress.value.name) {
        <div class="flex items-center p-2">
          <div class="flex-none mr-4">
            <span class="pi pi-map-marker"></span>
          </div>
          <div class="grow">
            <span class="font-bold">{{ organizationForm.controls.billAddress.value.name }}</span>.
            <span>{{ organizationForm.controls.billAddress.value.address }}</span>,
            <span>{{ organizationForm.controls.billAddress.value.city }}</span>,
            <span>{{ organizationForm.controls.billAddress.value.province }}</span>,
            <span>{{ organizationForm.controls.billAddress.value.postalCode }}</span>,
            <span>{{ organizationForm.controls.billAddress.value.country }}</span>
          </div>
          <div class="flex-none ml-4">
            <p-button icon="pi pi-trash" size="small" variant="text" severity="danger" (onClick)="removeBillAddress()"
              [pTooltip]="labels().removeAddress" showDelay="1000" hideDelay="300"></p-button>
          </div>
        </div>
        }
        <p-divider />
      }

      <div formArrayName="shippingAddress" class="">
        <span class="text-md font-bold mb-2">{{labels().shippingAddresses}}</span>
        @for(address of shippingAddress.controls; track $index; let i = $index) {
          <div class="flex items-center p-2 border-b border-gray-200">
            <div class="flex-none mr-4">
              <span class="pi pi-map-marker"></span>
            </div>
            <div class="grow">
              <span class="font-bold">{{ address.value.name }}</span>.
              <span>{{ address.value.address }}</span>,
              <span>{{ address.value.city }}</span>,
              <span>{{ address.value.province }}</span>,
              <span>{{ address.value.postalCode }}</span>,
              <span>{{ address.value.country }}</span>
            </div>
            <div class="flex-none ml-4 gap-2">
              @if (!isBillAddress(address.value) && type() === partner) {
                <p-button icon="pi pi-receipt" [pTooltip]="labels().isBillAddress" showDelay="1000" hideDelay="300" (onClick)="setAsBillAddress(i)" size="small" variant="text" />
              }
              <p-button icon="pi pi-trash" (onClick)="removeAddress(i)" [pTooltip]="labels().removeAddress" showDelay="1000" hideDelay="300" size="small" variant="text" severity="danger" />
            </div>
          </div>
        }
      </div>
      <div class="flex justify-center mt-4">
        <p-button [label]="labels().new" icon="pi pi-plus" (onClick)="openDialog()" size="small" variant="outlined"
          severity="secondary"></p-button>
      </div>
    </p-fieldset>
    <p-fieldset [legend]="labels().contactsSection" [toggleable]="true" [collapsed]="true" formGroupName="contact">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="col-span-1 md:col-span-2">
          <p-floatlabel variant="on">
            <input pInputText id="name" formControlName="name" autocomplete="off" fluid="true" pSize="small" />
            <label for="name">{{labels().name}}</label>

          </p-floatlabel>
        </div>

        <div class="col-span-1">
          <p-floatlabel variant="on">
            <input pInputText id="email" formControlName="email" autocomplete="off" fluid="true" pSize="small" />
            <label for="email">{{labels().email}}</label>
          </p-floatlabel>
        </div>
        <div class="col-span-1">
          <p-floatlabel variant="on">
            <input pInputText id="phone" formControlName="phone" autocomplete="off" fluid="true" pSize="small" />
            <label for="phone">{{labels().phone}}</label>
          </p-floatlabel>
        </div>
      </div>
    </p-fieldset>

    <div class="flex justify-between mt-6">
      <p-button [label]="labels().back" variant="text" severity="secondary" (onClick)="handleCancel()" />
      <p-button type="submit" [label]="labels().add" [disabled]="organizationForm.invalid || disabledAddBtn()" [loading]="loading()" />
    </div>

  </form>
</div>
<p-dialog [header]="'Add address'" [(visible)]="showDialog" [modal]="true" >
  <address-dialog [labels]="labels()" [type]="type()" (closeClick)="closeDialog()" (addClick)="createAddress($event)" />
</p-dialog>
<p-toast position="bottom-center" key="bc" (onClose)="handleToastClose()"/>
