<aside
  class="transition-width duration-300 h-full"
  [class.w-16]="isClosed()"
  [class.w-64]="!isClosed()"
>
<p-menu styleClass="!h-full !min-w-0 !border-0 !border-r-1 !rounded-none"  [model]="items()">
  <ng-template #start>
    <div class="flex justify-between px-2 py-2">
      <span *ngIf="!isClosed()" class="text-base font-semibold self-center truncate">{{ userStore.user().firstName}} {{
        userStore.user().lastName}}</span>
      <p-button icon="pi pi-bars" styleClass="!p-2" variant="outlined" severity="secondary" aria-label="Save"
        (onClick)="toggleSidebar()" size="small" />
    </div>
    <p-divider styleClass="!my-2"/>
  </ng-template>
  <ng-template #item let-item>
    <a pRipple [pTooltip]="item.tooltip" [tooltipOptions]="{ disabled: !this.isClosed() }" class="flex items-center p-menu-item-link menu-item-h" [routerLink]="item.routerLink" routerLinkActive="font-bold" #rla="routerLinkActive" [routerLinkActiveOptions]="item.routerLinkActiveOptions">
      <span  [ngClass]="[item.icon, 'p-1', rla.isActive ? 'active active-bg rounded-sm' : '']"></span>
      <span [ngClass]="[rla.isActive ? 'active' : 'text-base']" *ngIf="!isClosed()">{{ item.label }}</span>
    </a>
  </ng-template>
</p-menu>
</aside>


