import { inject, Injectable } from '@angular/core';
import { ApiRestService } from './api-rest.service';
import { Customer, Partner } from '@core/schemas';
import { Observable } from 'rxjs';
import { endpoints } from '@core/config';

/**
 * OrganizationService provides methods to manage partners and customers using ApiRestService.
 */
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private apiRestService = inject(ApiRestService);
  private partnersEndpoints = endpoints.partners;
  private customersEndpoints = endpoints.customers;

  /**
   * Retrieves all partners.
   * @returns An Observable of an array of Partner objects.
   */
  getPartners(): Observable<Partner[]> {
    return this.apiRestService.get<Partner[]>(this.partnersEndpoints.getAll);
  }

  /**
   * Retrieves a partner by its ID.
   * @param id - The ID of the partner to retrieve.
   * @returns An Observable of the Partner object.
   */
  getPartnerById(id: number): Observable<Partner> {
    return this.apiRestService.get<Partner>(`${this.partnersEndpoints.getById}/${id}`);
  }

  /**
   * Adds a new partner.
   * @param partner - The Partner object to add.
   * @returns An Observable of the added Partner object.
   */
  addPartner(partner: Partner): Observable<Partner> {
    return this.apiRestService.post<Partner>(this.partnersEndpoints.add, partner);
  }

  /**
   * Retrieves all customers.
   * @returns An Observable of an array of Customer objects.
   */
  getCustomers(): Observable<Customer[]> {
    return this.apiRestService.get<Customer[]>(this.customersEndpoints.getAll);
  }

  /**
   * Retrieves a customer by its ID.
   * @param id - The ID of the customer to retrieve.
   * @returns An Observable of the Customer object.
   */
  getCustomerById(id: number): Observable<Customer> {
    return this.apiRestService.get<Customer>(`${this.customersEndpoints.getById}/${id}`);
  }

  /**
   * Adds a new customer.
   * @param customer - The Customer object to add.
   * @returns An Observable of the added Customer object.
   */
  addCustomer(customer: Customer): Observable<Customer> {
    return this.apiRestService.post<Customer>(this.customersEndpoints.add, customer);
  }

  // TODO: other methods. put, delete...
}
