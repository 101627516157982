import { Route, Routes } from '@angular/router';
import {
  HomeViewComponent,
  LoginViewComponent,
  PartnerFormComponent,
  PartnersViewComponent,
  PartnerDetailsComponent,
  CustomerFormComponent,
  CustomerDetailsComponent,
  CustomersViewComponent
} from '@views/index';
import { AuthGuard, LoginGuard } from '@core/guards';

const redirectToHome: Route = {
  path: '**',
  redirectTo: '/'
};

export const routes: Routes = [
  {
    path: 'admin',
    children: [
      {
        path: 'partners',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'new',
            component: PartnerFormComponent
          },
          {
            path: ':id',
            component: PartnerDetailsComponent
          },
          {
            path: '',
            component: PartnersViewComponent
          }
        ]
      },
      {
        path: 'customers',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'new',
            component: CustomerFormComponent
          },
          {
            path: ':id',
            component: CustomerDetailsComponent
          },
          {
            path: '',
            component: CustomersViewComponent
          }
        ]
      },
      redirectToHome
    ]
  },
  {
    path: 'login',
    component: LoginViewComponent,
    canActivate: [LoginGuard]
  },
  {
    path: '',
    component: HomeViewComponent,
    canActivate: [AuthGuard]
  },
  redirectToHome
];
