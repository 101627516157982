<div class="p-4">
  <form [formGroup]="addressForm" (ngSubmit)="add()">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="col-span-1 md:col-span-2">
        <p-floatlabel variant="on">
          <input pInputText id="name" formControlName="name" autocomplete="off" fluid="true" pSize="small" />
          <label for="name">{{labels().name}}</label>

        </p-floatlabel>
      </div>
      <div class="col-span-1 md:col-span-2">
        <p-floatlabel variant="on">
          <input pInputText id="address" formControlName="address" autocomplete="off" fluid="true" pSize="small" />
          <label for="address">{{labels().address}}</label>

        </p-floatlabel>
      </div>
      <div class="col-span-1">
        <p-floatlabel variant="on">
          <input pInputText id="postalCode" formControlName="postalCode" autocomplete="off" fluid="true" pSize="small" />
          <label for="postalCode">{{labels().postalCode}}</label>
        </p-floatlabel>
      </div>
      <div class="col-span-1">
        <p-floatlabel variant="on">
          <input pInputText id="city" formControlName="city" autocomplete="off" fluid="true" pSize="small" />
          <label for="city">{{labels().city}}</label>
        </p-floatlabel>
      </div>
      <div class="col-span-1">
        <p-floatlabel variant="on">
          <input pInputText id="province" formControlName="province" autocomplete="off" fluid="true" pSize="small" />
          <label for="province">{{labels().province}}</label>
        </p-floatlabel>
      </div>
      <div class="col-span-1">
        <p-floatlabel variant="on">
          <input pInputText id="country" formControlName="country" autocomplete="off" fluid="true" pSize="small" />
          <label for="country">{{labels().country}}</label>
        </p-floatlabel>
      </div>
      @if (type() === partner) {
        <div class="col-span-1 md:col-span-2 my-2">
          <p-checkbox formControlName="isBillAddress" [binary]="true" inputId="isBillAddress" />
          <label for="isBillAddress" class="ml-2">{{labels().isBillAddress}}</label>
        </div>
      }

    </div>
    <div class="flex justify-end mt-6 gap-4">
      <p-button [label]="labels().back" severity="secondary" (click)="cancel()" size="small"/>
      <p-button type="submit" [label]="labels().saveAddress" severity="primary" [disabled]="addressForm.invalid" size="small"/>
    </div>
  </form>
</div>
