import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationFormComponent } from '@components/organization-form';
import { Organization } from '@core/enums';
import { I18nService } from '@core/i18n';
import { Customer } from '@core/schemas';
import { OrganizationService } from '@core/services';
import { TranslocoModule } from '@jsverse/transloco';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    OrganizationFormComponent
  ],
  templateUrl: './customer-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerFormComponent {
  private router = inject(Router);
  private i18nService = inject(I18nService);
  private organizationService = inject(OrganizationService);
  private subscription = new Subscription();

  loading = signal<boolean>(false);
  submitted = signal<boolean>(false);
  labels = signal<any>({});
  organizationType = Organization.customer;

  private organizationFormComponent = viewChild(OrganizationFormComponent);

  ngOnInit(): void {
    this.initLabels();
  }

  private initLabels(): void {
    this.subscription.add(
      combineLatest({
        customers: this.i18nService.selectTranslateObject('add', {}, 'customers'),
        organization: this.i18nService.selectTranslateObject('form', {}, 'organization-form')
      }).subscribe({
        next: (res) => {
          this.labels.set({
            ...res.customers,
            ...res.organization
          });
        },
        error: (error) => {
          console.log('error :>> ', error);
        }
      })
    );
  }

  handleSubmit(formValue: any): void {
    const customer = formValue as Customer;
    this.loading.set(true);
    this.subscription.add(
      this.organizationService.addCustomer(customer).subscribe({
        next: () => {
          this.loading.set(false);
          this.organizationFormComponent()?.addToast('success', this.labels().successFeedbackTitle, this.labels().successFeedbackMessage);
          setTimeout(() => {
            this.router.navigate(['/admin/customers']);
          }, 2000);
        },
        error: (error) => {
          this.loading.set(false);
          this.organizationFormComponent()?.addToast('error', 'Error', error.title);
          console.log('error :>> ', error);
        }
      })
    );
  }

  handleCancel(): void {
    this.router.navigate(['/admin/customers']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
