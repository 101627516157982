


<div class="h-screen flex flex-col">
  @if (!isLoginView()) {
    <tnp-header />
  }
  <div class="flex flex-1">
    @if (!isLoginView()) {
      <arquea-sidebar />
    }

    <main class="flex-1 container mx-auto py-4 px-2">
      <router-outlet />
    </main>
  </div>

  @if (!isLoginView()) {
    <tnp-footer />
  }
</div>


